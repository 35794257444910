import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import bscPink from 'contracts/abi/bscPink.json';
import bscViolet from 'contracts/abi/bscViolet.json';
import bscBUSD from 'contracts/abi/bscBUSD.json';
import bscStaking from 'contracts/abi/bscStaking.json';
import dex from 'contracts/abi/dex.json';
import {
  BSC_MAINNET_VIOLET_ADDRESS,
  BSC_MAINNET_PINK_ADDRESS,
  BUSD_MAINNET_ADDRESS,
  BSC_MAINNET_STAKING_ADDRESS, MAINNET_DEX_ADDRESS,
  // BSC_TESTNET_VIOLET_ADDRESS,
  // BSC_TESTNET_PINK_ADDRESS,
  // BUSD_TESTNET_ADDRESS,
  // BSC_TESTNET_STAKING_ADDRESS,
} from '../constants';

type Methods = {
  contractPink: any,
  contractViolet: any,
  contractBUSD: any,
  contractStaking: any,
  contractDex: any,
  provider: any,
};

export const useContract = (): Methods => {
  const web3 = useWeb3React();
  const provider = new Web3(Web3.givenProvider);

  let contractPink;
  let contractViolet;
  let contractBUSD;
  let contractStaking;
  let contractDex;

  switch (web3.chainId) {
    // Testnet
    // case 97: {
    //   // @ts-ignore
    //   contractPink = new provider.eth.Contract(bscPink, BSC_TESTNET_PINK_ADDRESS);
    //   // @ts-ignore
    //   contractViolet = new provider.eth.Contract(bscViolet, BSC_TESTNET_VIOLET_ADDRESS);
    //   // @ts-ignore
    //   contractBUSD = new provider.eth.Contract(bscBUSD, BUSD_TESTNET_ADDRESS);
    //   // @ts-ignore
    //   contractStaking = new provider.eth.Contract(bscStaking, BSC_TESTNET_STAKING_ADDRESS);
    //   break;
    // }

    case 56: {
      // @ts-ignore
      contractPink = new provider.eth.Contract(bscPink, BSC_MAINNET_PINK_ADDRESS);
      // @ts-ignore
      contractViolet = new provider.eth.Contract(bscViolet, BSC_MAINNET_VIOLET_ADDRESS);
      // @ts-ignore
      contractBUSD = new provider.eth.Contract(bscBUSD, BUSD_MAINNET_ADDRESS);
      // @ts-ignore
      contractStaking = new provider.eth.Contract(bscStaking, BSC_MAINNET_STAKING_ADDRESS);
      // @ts-ignore
      contractDex = new provider.eth.Contract(dex, MAINNET_DEX_ADDRESS);
      break;
    }

    default: {
      contractPink = null;
      contractViolet = null;
      contractBUSD = null;
      contractStaking = null;
      contractDex = null;
    }
  }

  return {
    contractPink,
    contractViolet,
    contractBUSD,
    contractStaking,
    contractDex,
    provider,
  };
};

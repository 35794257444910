import { Text, Title } from 'components';
import React, { FC } from 'react';
import manImage from '../../../assets/images/gamefi/gamefi_04_1.png';
import labImage from '../../../assets/images/gamefi/gamefi_04_2.png';
import styles from './styles.module.scss';

export const Lab: FC = () => (
  <div className={styles.container}>
    <div className={styles.lab}>
      <Title className={styles.title} size="l">
        WELCOME TO THE
        {' '}
        <span className={styles.titleBold}>
          LAB
        </span>
      </Title>
      <img src={labImage} alt='labImage' className={styles.labImage} />
      <Text className={styles.text}>
        Craft and merge for the victory!
      </Text>
    </div>
    <img src={manImage} alt='image' className={styles.manImage} />
  </div>
);

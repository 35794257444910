import React, { FC } from 'react';
import Select from 'react-select';
import cn from 'classnames';

import styles from './styles.module.scss';

export type Value = {
  value: any,
  label: string
};

type Props = {
  className?: string,
  name?: string,
  error?: string | boolean,
  value: Value,
  options: Value[],
  onChange: (Value) => void,
  onBlur?: (Value) => void,
};

export const Dropdown: FC<Props> = ({
  className,
  value,
  options,
  onChange,
  name,
  onBlur,
  error,
}) => {
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  const stylesSelect = {
    control: (s) => ({
      ...s,
      backgroundColor: '#2C2C2C',
      height: '48px',
      borderColor: '#2C2C2C',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#2C2C2C',
      },
      boxShadow: 'none',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    singleValue: (styles) => ({ ...styles, color: '#ffffff', fontSize: '16px' }),
    option: (s) => ({
      ...s,
      backgroundColor: '#2C2C2C',
      color: '#ffffff',
      fontSize: '16px',
      cursor: 'pointer',
      '&:hover': {
        color: '#2C2C2C',
        backgroundColor: '#b9b8b8',
      },
    }),
    menu: (s) => ({
      ...s,
      backgroundColor: '#2C2C2C',
      color: '#ffffff',
    }),
    menuList: (base) => ({
      ...base,
      '&::-webkit-scrollbar': {
        background: 'rgba(255, 255, 255, 0.25)',
        width: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgb(165, 70, 68)',
      },
      '&::-webkit-resizer': {
        width: '2px',
      },
    }),
  };

  return (
    <div className={cn(className, styles.selectWrap, { [styles.error]: !!error })}>
      <Select
        className={styles.select}
        onBlur={onBlur}
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        styles={stylesSelect}
        isSearchable={false}
        maxMenuHeight={120}
        captureMenuScroll
        menuShouldBlockScroll={false}
      />
    </div>
  );
};

import React from 'react';

import { Button } from 'components';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../../constants';
import stacking from '../../../../../assets/images/lending/carousel/stacking.png';
import styles from './styles.module.scss';

export const Slide2 = () => (
  <div>
    <div className={styles.slideWrap}>
      <img className={styles.img} src={stacking} alt='stacking' />
      <div className={styles.slide}>
        <p className={styles.title}>
          Staking
        </p>
        <h3 className={styles.text}>
          Stake to fuel up your tanks!
        </h3>
        <div className={styles.buttonWrap}>
          <Link to={ROUTES.staking} className={styles.link}>
            <Button className={styles.button}>
              STAKING
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

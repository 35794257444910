import React, { FC } from 'react';

import { BuyCrosscar } from './BuyCrosscar';
import { Carousel } from './Carousel';
import { Roadmap } from './Roadmap';
// import { TheDream } from './TheDream';
import { TheCollection } from './TheCollection';
import { Mint } from './Mint';
import { Partners } from './Partners';

import styles from './styles.module.scss';

export const Main: FC = () => (
  <div className={styles.container}>
    <BuyCrosscar />
    <Carousel />
    <div className={styles.wrap}>
      {/* <TheDream /> */}
      <TheCollection />
      <Roadmap />
      <Partners />
      <Mint />
    </div>
  </div>
);

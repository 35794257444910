import React from 'react';

import { Info } from './Info';

import styles from './styles.module.scss';

export const CarsItem = () => (
  <div className={styles.container}>
    <Info />
  </div>
);

import React, { FC } from 'react';
import { Title, Text } from 'components';
import styles from './styles.module.scss';
import { Gallery } from './Gallery';

export const TheGear: FC = () => (
  <div className={styles.container}>
    <Title className={styles.title} size="l">
      THE
      {' '}
      <span className={styles.titleBold}>
        GEAR
      </span>
    </Title>
    <Text className={styles.subtitle}>
      What you need to compete.
    </Text>
    <Gallery />
  </div>
);

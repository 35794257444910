import React, { FC, useState } from 'react';

import { useMint } from 'hooks';

import cn from 'classnames';
import technoImg from 'assets/images/lending/buyCrosscar/carL.png';
import technoImgName from 'assets/images/lending/buyCrosscar/carNameL.png';
import { Button, Dropdown, ModalWrap } from 'components';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  onRequestClose: (isOpen: boolean) => void,
};

export type ValueDropdown = {
  value: number,
  label: string
};

export const COUNT_LIST = [
  { value: 1, label: '1 X TECHNOMANIAC' },
  { value: 2, label: '2 X TECHNOMANIACS' },
  { value: 5, label: '5 X TECHNOMANIACS' },
  { value: 10, label: '10 X TECHNOMANIACS' },
  { value: 15, label: '15 X TECHNOMANIACS' },
  { value: 20, label: '20 X TECHNOMANIACS' },
];

export const MintPink: FC<Props> = ({ isOpen, onRequestClose }) => {
  const [count, setCount] = useState<ValueDropdown>({ value: 1, label: '1 X TECHNOMANIAC' });
  const { mint, isLoading } = useMint('pink', count.value);

  return (
    <ModalWrap onRequestClose={onRequestClose} isOpen={isOpen} >
      <div className={styles.container}>
        <div className={cn(styles.car, styles.carTechno)}>
          <div className={styles.card}>
            <div className={styles.cardImd}>
              <img
                  className={styles.cardImdMain}
                  src={technoImg}
                  alt="car"
                />
              <img
                  className={styles.cardImdName}
                  src={technoImgName}
                  alt="car"
                />
            </div>
            <div className={styles.cardText}>
              <p>
                Technomaniacs’ credo is the development
                <br />
                of technology is the only way of making progress.
              </p>
              <ul className={styles.cardTextList}>
                <li>
                  If you believe in technological superiority which will secure
                  the victory for you and your faction, don’t hesitate to join.
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.description}>
            <div>
              <div className={styles.descriptionTitle}>
                <h2>
                  Mint a crosscar
                </h2>
              </div>
              <div className={styles.descriptionPrice}>
                <p>
                  It costs 9.99 BUSD to mint a CrossCar
                </p>
              </div>

              <Dropdown
                className={styles.descriptionDrop}
                value={count}
                options={COUNT_LIST}
                onChange={setCount}
              />
              <ol className={styles.descriptionList}>
                <li>
                  Sharpen attack skills
                </li>
                <li>
                  True pank - is a pixelized punk
                </li>
                <li>
                  Realium is the source of power
                </li>
              </ol>
            </div>
            <Button className={styles.buttonMint} onClick={mint} loading={isLoading}>
              Mint
            </Button>
          </div>
        </div>
      </div>
    </ModalWrap>
  );
};

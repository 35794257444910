import React from 'react';

import { Button } from 'components';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../../constants';
import craft from '../../../../../assets/images/lending/carousel/craft.png';
import styles from './styles.module.scss';

export const Slide3 = () => (
  <div>
    <div className={styles.slideWrap}>
      <img src={craft} className={styles.img} alt='Craft' />
      <div
        className={styles.slide}
      >
        <p className={styles.title}>
          Craft
        </p>
        <h3 className={styles.text}>
          Craft and merge for the victory!
        </h3>
        <div className={styles.buttonWrap}>
          <Link to={ROUTES.craft} className={styles.link}>
            <Button className={styles.button}>
              CRAFT
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import technoBtn from 'assets/images/lending/Mint/techno-btn.png';
import awokenBtn from 'assets/images/lending/Mint/awoken-btn.png';
import technoImg from 'assets/images/lending/buyCrosscar/carL.png';
import technoImgName from 'assets/images/lending/buyCrosscar/carNameL.png';
import technoTitle from 'assets/images/lending/Mint/techno-logo.png';
import awokenImg from 'assets/images/lending/buyCrosscar/carR.png';
import awokenImgName from 'assets/images/lending/buyCrosscar/carNameR.png';
import awokenTitle from 'assets/images/lending/Mint/awoken-logo.png';
import { Button } from 'components';
import { useLocation } from 'react-router-dom';
import { MintPink, MintViolet } from './modal';

import styles from './styles.module.scss';

export const Mint = () => {
  const { pathname } = useLocation();
  const [showTechno, setShowTechno] = useState<boolean>(true);
  const [isOpenModalPink, setOpenModalPink] = useState<boolean>(false);
  const [isOpenModalViolet, setOpenModalViolet] = useState<boolean>(false);

  useEffect(() => {
    if (pathname.includes('1')) {
      setOpenModalPink(true);
    }
    if (pathname.includes('2')) {
      setOpenModalViolet(true);
    }
  }, [pathname]);

  return (
    <div className={styles.mint}>
      <div className={styles.blockDecor} />
      <div className={styles.container}>
        <div className={styles.blockButtons}>
          <button
            className={cn(styles.buttonCar, { [styles.buttonCarActive]: showTechno })}
            onClick={() => setShowTechno(true)}
          >
            <img
              src={technoBtn}
              alt="logoBtn"
              width="125"
            />
          </button>
          <button
            className={cn(styles.buttonCar, styles.buttonCarAwoken, { [styles.buttonCarActive]: !showTechno })}
            onClick={() => setShowTechno(false)}
          >
            <img
              src={awokenBtn}
              alt="logoBtn"
              width="125"
            />
          </button>
        </div>
        <div className={styles.blockCar}>
          <div className={cn(styles.car, styles.carTechno, { [styles.carActive]: showTechno })}>
            <div className={styles.card}>
              <div className={styles.cardImd}>
                <img
                  className={styles.cardImdMain}
                  src={technoImg}
                  alt="car"
                />
                <img
                  className={styles.cardImdName}
                  src={technoImgName}
                  alt="car"
                />
              </div>
              <div className={styles.cardText}>
                <p>
                  Technomaniacs’ credo is the development
                  <br />
                  of technology is the only way of making progress.
                </p>
                <ul className={styles.cardTextList}>
                  <li>
                    ▷ Sharpen attack skills ◁
                  </li>
                  <li>
                    ▷ True pank - is a pixelized punk ◁
                  </li>
                  <li>
                    ▷ Realium is the source of power ◁
                  </li>
                </ul>
              </div>
              <Button className={styles.buttonMint} onClick={() => setOpenModalPink(true)}>
                Mint
              </Button>
            </div>
            <div className={styles.description}>
              <div className={styles.descriptionTitle}>
                <img
                  src={technoTitle}
                  width="100%"
                />
              </div>
              <div className={styles.descriptionText}>
                <p>
                  Technomaniacs’ credo is the development of technology is the only way of making progress.
                  Be it interstellar travelling or creating a new world of Punks, they are willing
                  to sacrifice everything they have for the sake of science.
                </p>
                <p>
                  In the battle for Realium, Technos will fight until the bitter end,
                  since it is a chance to get unprecedented power. Pixelazation won’t
                  stop them from keeping their eyes on the prize.
                </p>
                <p>
                  If you believe in technological superiority which will secure the victory
                  for you and your faction, don’t hesitate to join.
                </p>
              </div>
              <Button className={styles.buttonMint} onClick={() => setOpenModalPink(true)}>
                Mint
              </Button>
            </div>
          </div>
          <div className={cn(styles.car, styles.carAwoken, { [styles.carActive]: !showTechno })}>
            <div className={styles.card}>
              <div className={styles.cardImd}>
                <img
                  className={styles.cardImdMain}
                  src={awokenImg}
                  alt="car"
                />
                <img
                  className={styles.cardImdName}
                  src={awokenImgName}
                  alt="car"
                />
              </div>
              <div className={styles.cardText}>
                <p>
                  From their perspective, true power
                  <br />
                  lies inside an individual. Awokens' strategy.
                  <br />
                </p>
                <ul className={styles.cardTextList}>
                  <li>
                    ▷ Using defense skills and traps ◁
                  </li>
                  <li>
                    ▷ Pixelization is a mere smokescreen ◁
                  </li>
                  <li>
                    ▷ Realium is healing essence ◁
                  </li>
                </ul>
              </div>
              <Button className={styles.buttonMint} onClick={() => setOpenModalViolet(true)}>
                Mint
              </Button>
            </div>
            <div className={styles.description}>
              <div className={styles.descriptionTitle}>
                <img
                  src={awokenTitle}
                  width="100%"
                />
              </div>
              <div className={styles.descriptionText}>
                <p>
                  From their perspective, true power lies inside an individual.
                  Self-development is the only way of changing the world around.
                  The faction members strongly believe that pixelazation is a mere smokescreen,
                  a hurdle on the way to expanding the boundaries and using inner resources fully.
                </p>
                <p>
                  The chief virtues are sharp mind and willpower.
                  Realium is healing essence which will help to destroy all the limitations.
                </p>
                <p>
                  If it resonates with you, take the mystical path of awakening from pixilated sleep.
                </p>
              </div>
              <Button className={styles.buttonMint} onClick={() => setOpenModalViolet(true)}>
                Mint
              </Button>
            </div>
          </div>
        </div>
      </div>
      <MintPink isOpen={isOpenModalPink} onRequestClose={() => setOpenModalPink(false)} />
      <MintViolet isOpen={isOpenModalViolet} onRequestClose={() => setOpenModalViolet(false)} />
    </div>
  );
};

import React, {
  FC, useEffect, useRef,
} from 'react';

import { Card } from 'components';

import styles from './styles.module.scss';
import { ROUTES } from '../../../constants';
import { Collection } from '../interface';

interface Image {
  idx: number;
  rank: number;
  type: string;
  attributes: string[];
  offer: {
    min_value: string,
    seller: string,
  };
}

interface Props {
  images: Image[];
  total: number;
  getImages: (isStackImage?: boolean) => void;
  collection: Collection;
}

export const List: FC<Props> = ({
  images,
  total,
  getImages,
  collection,
}) => {
  const cardsRef = useRef<{ [index: number]: HTMLAnchorElement | null }>({});
  const observer = useRef<IntersectionObserver | null>(null);
  const prevImages = useRef<Image[]>([]);

  if (images !== prevImages.current) {
    prevImages.current = images;
    cardsRef.current = {};
  }

  const addObserver = () => {
    observer.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && images.length < total) {
          getImages(true);
        }
      });
    });
    const cardsIndexes = Object.keys(cardsRef.current);
    const lastIndex = cardsIndexes[cardsIndexes.length - 1];
    if (cardsRef.current[lastIndex]) observer.current.observe(cardsRef.current[lastIndex]);
  };

  useEffect(() => {
    addObserver();
  }, [images]);

  const getLink = typeof collection.number === 'number' ?
    (idx) => ROUTES.carsItemLink(collection.name, idx, collection.number) :
    (idx) => ROUTES.punksItemLink(collection.name, idx);

  return (
    <div className={styles.list}>
      <div className={styles.container}>
        {
          images.map((img, index) => {
            let value = img.offer?.min_value;
            const valueArr = value?.padStart(19, '0').split('');
            if (value.length > 18) valueArr.splice(value.length - 18, 0, '.');
            else valueArr.splice(1, 0, '.');
            value = String(+valueArr.join(''));
            return (
              <Card
                key={img.idx}
                idx={img.idx}
                ref={(ref) => {
                  cardsRef.current[index] = ref;
                }}
                to={getLink(img.idx)}
                collection={collection}
                rank={img.rank}
                type={img.type}
                tags={img.attributes}
                value={value}
              />
            );
          })
        }
      </div>
    </div>
  );
};

import React, { FC } from 'react';

import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  classNameWrap?: string,
  className?: string,
};

export const Loader: FC<Props> = ({ className, classNameWrap }) => (
  <div className={cn(classNameWrap)}>
    <div className={cn(styles.loader, className)} />
  </div>
);

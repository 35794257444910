import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';

import { Loader } from 'components';
import { FactoryItem } from './components';

import styles from './styles.module.scss';

type Token = {
  name: string,
  tokenImg1: string,
  tokenImg2: string,
  address: string,
  id: number,
};

export const Factory = () => {
  const { account, chainId } = useWeb3React();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [tokens, setTokens] = useState<Token[] | []>([]);

  const handleGetTokens = async () => {
    setIsLoading(true);
    try {
      if (!account) return;

      const response = await fetch('https://api.crosspunks.com/logo', {
        method: 'GET',
      });

      const result = await response.json();

      const tokensState = result.logo.map((token) => ({
        name: token.name,
        tokenImg1: token.img1,
        tokenImg2: token.img2,
        address: token.address,
        id: token.pool_id,
      }));

      setTokens(tokensState);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast.error('Reject');
      console.log(e);
    }
  };

  useEffect(() => {
    handleGetTokens();
  }, [account, chainId]);

  return (
    <section className={styles.factory}>
      <div className={styles.container}>
        <div className={styles.factoryTitle}>
          <h2 className={styles.factoryTitleH}>
            Factory
          </h2>
        </div>
        <div className={styles.factoryCards}>
          {
            !isLoading ? (
              tokens.map((token, i) => (
                <div key={`${token.name}-${i}`}>
                  <FactoryItem
                    name={token.name}
                    tokenImg1={token.tokenImg1}
                    tokenImg2={token.tokenImg2}
                    address={token.address}
                    id={token.id}
                  />
                </div>
              ))
            ) : <Loader className={styles.loader} />
          }
        </div>
      </div>
    </section>
  );
};

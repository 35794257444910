import React, { FC, useState } from 'react';

import { useMint } from 'hooks';

import cn from 'classnames';
import { Button, Dropdown, ModalWrap } from 'components';

import awokenImg from 'assets/images/lending/buyCrosscar/carR.png';
import awokenImgName from 'assets/images/lending/buyCrosscar/carNameRMirror.png';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  onRequestClose: (isOpen: boolean) => void,
};

export type ValueDropdown = {
  value: number,
  label: string
};

export const COUNT_LIST = [
  { value: 1, label: '1 X AWOKENSAGE' },
  { value: 2, label: '2 X AWOKENSAGES' },
  { value: 5, label: '5 X AWOKENSAGES' },
  { value: 10, label: '10 X AWOKENSAGES' },
  { value: 15, label: '15 X AWOKENSAGES' },
  { value: 20, label: '20 X AWOKENSAGES' },
];

export const MintViolet: FC<Props> = ({ isOpen, onRequestClose }) => {
  const [count, setCount] = useState<ValueDropdown>({ value: 1, label: '1 X AWOKENSAGE' });

  const { mint, isLoading } = useMint('violet', count.value);

  return (
    <ModalWrap onRequestClose={onRequestClose} isOpen={isOpen} >
      <div className={styles.container}>
        <div className={cn(styles.car, styles.carAwoken)}>
          <div className={styles.card}>
            <div className={styles.cardImd}>
              <img
                className={styles.cardImdMain}
                src={awokenImg}
                alt="car"
              />
              <img
                className={styles.cardImdName}
                src={awokenImgName}
                alt="car"
              />
            </div>
            <div className={styles.cardText}>
              <p>
                From their perspective, true power
                <br />
                lies inside an individual. Awokens' strategy.
                <br />
              </p>
              <ul className={styles.cardTextList}>
                <li>
                  If it resonates with you, take the mystical path of awakening from pixilated sleep.
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.description}>
            <div>
              <div className={styles.descriptionTitle}>
                <h2>
                  Mint a crosscar
                </h2>
              </div>
              <div className={styles.descriptionPrice}>
                <p>
                  It costs 9.99 BUSD to mint a CrossCar
                </p>
              </div>

              <Dropdown
                className={styles.descriptionDrop}
                value={count}
                options={COUNT_LIST}
                onChange={setCount}
              />

              <ol className={styles.descriptionList}>
                <li>
                  Using defense skills and traps.
                </li>
                <li>
                  Pixelization is a mere smokescreen.
                </li>
                <li>
                  Realium is healing essence.
                </li>
              </ol>
            </div>
            <Button className={styles.buttonMint} onClick={mint} loading={isLoading}>
              Mint
            </Button>
          </div>
        </div>
      </div>
    </ModalWrap>
  );
};

import React from 'react';

import { Button } from 'components';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../../constants';
import cars from '../../../../../assets/images/lending/carousel/cars.png';
import styles from './styles.module.scss';

export const Slide1 = () => (
  <div>
    <div className={styles.slideWrap}>
      <img className={styles.car} src={cars} alt='cars' />
      <div className={styles.slide}>
        <p className={styles.title}>
          GameFI
        </p>
        <h3 className={styles.text}>
          Challenge other players
          <br />
          and earn in PVP game
        </h3>
        <div className={styles.buttonWrap}>
          <Link to={ROUTES.mint} className={styles.link}>
            <Button className={styles.button}>
              MINT
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

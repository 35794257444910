import React, { FC } from 'react';
import { Gamefi } from './Gamefi';
import { TheGear } from './TheGear';
import { Staking } from './Staking';
import { Lab } from './Lab';
import { Cards } from './Cards';
import { Mint } from './Mint';

import styles from './styles.module.scss';

export const Game: FC = () => (
  <div className={styles.container}>
    <div className={styles.wrap}>
      <Gamefi />
      <TheGear />
      <Staking />
      <Lab />
      <Cards />
      <Mint />
    </div>
  </div>
);

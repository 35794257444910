import Web3 from 'web3';
import BN from 'bn.js';

type Props = {
  totalAmount: BN,
  account: string | null | undefined,
  contract: any,
  address: string,
};

export const approve = async ({
  account, totalAmount, contract, address,
}: Props) => {
  /**
   * number of tokens allowed
   */
  const allowance = await contract?.methods?.allowance(
    account,
    address,
  ).call();
  /**
   * If there is not enough money to make a transaction, the statement is not called
   */
  if (Web3.utils.toBN(allowance).lt((Web3.utils.toBN(totalAmount.toString())))) {
    /**
     * is called to resolve the transaction
     */
    const estimateGasApprove = await contract?.methods?.approve(
      address,
      totalAmount,
    )
      .estimateGas(
        { from: account },
      );

    await contract?.methods.approve(
      address,
      totalAmount,
    ).send({ from: account, gas: estimateGasApprove });
  }
};

import React, { FC, useState } from 'react';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import { useContract } from 'hooks';
import { Button, InputAmountInfo } from 'components';
import { useWeb3React } from '@web3-react/core';
import styles from './styles.module.scss';

type Props = {
  idx: string,
  handleGetInfoNFT: () => void,
};

export const Offer: FC<Props> = ({ idx, handleGetInfoNFT }) => {
  const { contractDex } = useContract();
  const [isLoader, setLoader] = useState<boolean>(false);
  const [price, setPrice] = useState<string>('');

  const { account } = useWeb3React();

  const handleOffer = async () => {
    try {
      setLoader(true);
      const estimateGas = await contractDex?.methods?.offerPunkForSale(idx, Web3.utils.toWei(price, 'ether'))
        .estimateGas({ from: account });

      await contractDex?.methods?.offerPunkForSale(idx, Web3.utils.toWei(price, 'ether'))
        .call({ from: account, gas: estimateGas });
      setLoader(false);
      toast.success('Offer successful');
      handleGetInfoNFT();
    } catch (e) {
      console.log(e);
      setLoader(false);
      toast.error('Offer reject');
    }
  };

  return (
    <>
      <div className={styles.priceContainer}>
        <InputAmountInfo onChange={(e) => setPrice(e.target.value)} value={price} />
      </div>
      <Button
        className={styles.buyButton}
        loading={isLoader}
        onClick={handleOffer}
      >
        OFFER
      </Button>
    </>
  );
};

import React, {
  MouseEventHandler, MutableRefObject,
  PropsWithChildren,
  useState,
} from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Loader } from 'components';

import { ReactComponent as Binance } from 'assets/images/logo/binance.svg';

import { HOST } from '../../constants/api';

import styles from './styles.module.scss';
import { Collection } from '../../screens/Gallery/interface';

type Props = {
  className?: string,
  src?: string,
  onClick?: MouseEventHandler,
  loading?: boolean;
  leftIcon?: React.ReactElement;
  idx?: number;
  to?: string;
  collection?: Collection;
  value?: string;
  tags?: string[] | { trait_type: string, value: string }[];
  rank?: number;
  type?: string;
};

const tagColors = {
  purple: {
    bg: '#7C40DC',
    text: '#FFFFFF',
  },
};

export const Tag = ({
  text = 'TAGS',
  color = 'purple',
}) => {
  const colors = tagColors[color] || tagColors.purple;
  return (
    <div className={styles.tag} style={{ backgroundColor: colors.bg }}>
      <p className={styles.tagText} style={{ color: colors.text }}>
        {text}
      </p>
    </div>
  );
};

const collectionNames = {
  punks: 'CrossPunk',
  cars: 'CrossCar',
};

const collectionNumbers = {
  1: 'TechnoManiacs',
  2: 'Awokensages',
};

type ContainerComponentProps = {
  className?: string,
  to?: string;
};

const ContainerComponent = React.forwardRef<HTMLAnchorElement | HTMLDivElement,
PropsWithChildren<ContainerComponentProps>>(({
  to,
  children,
  ...rest
}, ref) => {
  if (to) {
    return (
      <Link ref={ref as MutableRefObject<HTMLAnchorElement>} to={to} {...rest}>
        {children}
      </Link>
    );
  }
  return (
    <div ref={ref as MutableRefObject<HTMLDivElement>} {...rest}>
      {children}
    </div>
  );
});

export const Card = React.forwardRef<HTMLAnchorElement, PropsWithChildren<Props>>(({
  className,
  idx,
  src,
  to,
  collection,
  value,
  tags,
  rank,
  type,
}, ref) => {
  const [isLoading, setLoading] = useState<boolean>(true);

  const collectionUrlNumber = typeof collection?.number === 'number' ? `${collection.number}/` : '';

  const renderedTags = tags?.slice(0, 3) || [];
  const withEllipsis = renderedTags.length < (tags || []).length;
  return (
    <ContainerComponent
      to={to}
      ref={ref}
      className={cn(styles.card, className)}
    >
      <div className={styles.imgWrapper}>
        {isLoading ? <Loader /> : null}
        <img
          className={cn(styles.img, { [styles.imgLoad]: isLoading })}
          src={src || `${HOST}/${collection?.name}/img/${collectionUrlNumber}${idx}.png`}
          alt={`#${idx}`}
          onLoad={(e) => {
            if (e) setLoading(false);
          }}
        />
      </div>
      <div className={styles.info}>
        <h5 className={styles.name}>
          {`${collectionNames[collection?.name || 'punks']} ${idx}`}
        </h5>
        <div className={styles.row}>
          <p className={styles.subinfo}>
            {collection?.number ? collectionNumbers[collection.number] : type}
          </p>
          {value ? (
            <div className={styles.currency}>
              <p className={styles.subinfo}>
                {value}
              </p>
              <Binance className={styles.currencyIcon} />
            </div>
          ) : null}
        </div>
        {rank ? (
          <p className={styles.rank}>
            {`Rank: ${rank}`}
          </p>
        ) : null}
        <div className={styles.tagsRow}>
          {renderedTags.map((tag) => {
            let tagType = tag;
            let tagValue = tag;
            if (typeof tag === 'object') {
              tagType = tag?.trait_type;
              tagValue = tag?.value;
            }
            return (
              <Tag key={tagType + tagValue} text={tagValue} />
            );
          })}
          {withEllipsis && (
            <p className={styles.tagsEllipsis}>
              ...
            </p>
          )}
        </div>
      </div>
    </ContainerComponent>
  );
});

import React, { FC } from 'react';
import { Title } from 'components';
import styles from './styles.module.scss';
import { StepperChain } from './StepperChain';
import { RoadmapPic } from './RoadmapPic';

export const Roadmap: FC = () => (
  <div className={styles.container}>
    <div className={styles.wrap}>
      <Title className={styles.title} size="l">
        ROADMAP
      </Title>
      <StepperChain />
      <RoadmapPic />
    </div>
  </div>
);

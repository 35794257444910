import { useState } from 'react';
import { useContract } from 'hooks';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';

type Methods = {
  isLoading: boolean,
  withdraw: () => Promise<void> | void,
};

export const useWithdraw = (id: number, amount: string, handleGetTokens: () => Promise<void> | void): Methods => {
  const { contractStaking } = useContract();
  const { account } = useWeb3React();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const withdraw = async () => {
    try {
      setIsLoading(true);

      const totalAmount = Web3.utils.toBN(Web3.utils.toWei(amount, 'ether'));

      const estimateGas = await contractStaking?.methods?.withdraw(id, totalAmount)
        .estimateGas({ from: account });

      await contractStaking?.methods?.withdraw(id, totalAmount).send({ from: account, gas: estimateGas });

      toast.success('Withdraw successful');
      setIsLoading(false);
      handleGetTokens();
    } catch (e) {
      toast.error('Withdraw rejected');
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    withdraw,
  };
};

import React, { FC, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Loader, Title } from 'components';
import styles from './styles.module.scss';

export const Blog: FC = () => {
  const [articles, setArticles] = useState<any>([]);
  const [isLoader, setLoader] = useState<boolean>(false);

  const getArticles = () => {
    try {
      setLoader(true);
      fetch('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40crosspunks')
        .then((data) => data.json())
        .then((imageResult) => {
          setArticles(imageResult?.items);
        })
        .catch(() => {
          toast.error('Something went wrong! ');
        });
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.blog}>
        <Title className={styles.title} size="l">
          LATEST
          {' '}
          <span className={styles.subtitle}>
            NEWS
          </span>
        </Title>

        {isLoader ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <div className={styles.newsArticles}>
            {articles.map((article) => {
              const date = new Date(
                article?.pubDate,
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              });

              return (
                <a key={article?.title} href={article?.guid} className={styles.article} target="_blank">
                  <img src={article.thumbnail} alt="img" />
                  <p className={styles.newsArticleTitle}>
                    {article?.title}
                  </p>
                  <p className={styles.date}>
                    {date}
                  </p>
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { InputProps } from '@mui/material/Input';
import InputBase from '@mui/material/InputBase';
import cn from 'classnames';

import { ReactComponent as Search } from 'assets/icons/search.svg';

import styles from './styles.module.scss';

type Props = {
  onChange: InputProps['onChange'],
  onFocus?: InputProps['onFocus'],
  value: InputProps['value'],
  className?: InputProps['className'],
  placeholder?: InputProps['placeholder'],
  type?: 'search',
  disabled?: InputProps['disabled'],
  name?: InputProps['name'],
  onBlur?: InputProps['onBlur'],
};

const paramsByType = {
  search: {
    startAdornment: <Search className={styles.icon} />,
  },
};

export const Input: FC<Props> = ({
  className,
  placeholder = '',
  onChange,
  onFocus,
  value,
  type,
  disabled,
  name,
  onBlur,
}) => {
  const params = type && paramsByType[type];
  return (
    <InputBase
      name={name}
      disabled={disabled}
      className={cn(styles.input, className)}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      startAdornment={params?.startAdornment}
    />
  );
};

import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Loader } from '../Loader';

export enum PreloaderColors {
  primary = 'primary',
  secondary = 'secondary',
}

export enum PreloaderSizes {
  small = 'small',
  normal = 'normal',
  big = 'big',
}

export type Props = PropsWithChildren<{
  isLoading: boolean;
  className?: string;
  color?: keyof typeof PreloaderColors;
  size?: keyof typeof PreloaderSizes;
  style?: CSSProperties;
}>;

export type PreloaderContentProps = Omit<Props, 'isLoading' | 'children'>;

export const PreloaderContent: FC<PreloaderContentProps> = ({
  className,
  color = 'primary',
  size = 'normal',
  style,
}) => (
  <div
    style={style}
    className={cn(styles.wrap, styles[color], styles[size], className)}
  >
    <Loader />
  </div>
);

export const Preloader: FC<Props> = ({
  isLoading,
  className,
  color,
  size,
  children,
}) => {
  if (isLoading) {
    return <PreloaderContent size={size} color={color} className={className} />;
  }

  return <>
    {children}
  </>;
};

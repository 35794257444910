import { generatePath } from 'react-router';

export const ROUTES = {
  root: '/',
  factory: '/factory',
  myPunks: '/myPunks',
  gallery: '/gallery',

  punksItem: '/punks-item/:collectionName/:idx/',
  punksItemLink: (collectionName, idx) => generatePath(
    ROUTES.punksItem,
    { collectionName, idx },
  ),
  carsItem: '/cars-item/:collectionName/:collectionNumber/:idx/',
  carsItemLink: (collectionName, idx, collectionNumber) => generatePath(
    ROUTES.carsItem,
    { collectionName, idx, collectionNumber },
  ),

  marketplace: '/marketplace',
  contract: '/contract',
  mint: '/mint',
  game: '/gamefi',
  staking: '/staking',
  craft: '/craft',
  blog: '/blog',
  bridge: '/bridge',
};

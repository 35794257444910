import { Title } from 'components';
import React, { FC } from 'react';
import image from '../../../assets/images/gamefi/gamefi_05.png';
import styles from './styles.module.scss';

export const Cards: FC = () => (
  <div className={styles.container}>
    <img src={image} alt='image' className={styles.image} />
    <Title className={styles.title} size="l">
      CARDS
    </Title>
  </div>
);

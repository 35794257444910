import React, { FC, useState } from 'react';

import { Loader } from 'components';

import cn from 'classnames';
import styles from './styles.module.scss';

export const Bridge: FC = () => {
  const [isLoading, setLoading] = useState<boolean>(true);

  return (
    <div className={styles.container}>
      <div className={cn(styles.loader, { [styles.loaderActive]: isLoading })}>
        <Loader />
      </div>
      <iframe
        onLoad={() => {
          setLoading(false);
        }}
        className={cn(styles.iframe, { [styles.iframeActive]: !isLoading })}
        // eslint-disable-next-line max-len
        src='https://widget.xp.network/?widget=true&background=2C1623&panelBackground=2C1623&modalBackground=2C1623&color=fff&fontSize=16&btnColor=000&btnBackground=fff&btnRadius=9&fontFamily=Roboto&chains=Ethereum-BSC&cardBackground=2C1623&cardBackgroundBot=2C1623&cardColor=ffffff&cardRadius=25&secondaryColor=fff&accentColor=fff&borderColor=988b8b&iconColor=fff&tooltipBg=1D212A&tooltipColor=ffffff&wallets=MetaMask-false&bridgeState=undefined&showLink=true&affiliationFees=1'
        frameBorder='0' width="100%" height="100%"
      />
    </div>
  );
};

import React, { FC } from 'react';

import {
  Tag,
} from 'components';
import { HOST } from 'constants/api';

import { useWeb3React } from '@web3-react/core';
import { Image } from '../interface';
import styles from './styles.module.scss';
import { Withdraw } from './Withdrow';
import { Offer } from './Offer';
import { Buy } from './Buy';

const collectionNames = {
  punks: 'CrossPunk',
  cars: 'CrossCar',
};

const collectionNumbers = {
  1: 'TechnoManiacs',
  2: 'Awokensages',
};

type Props = {
  fetching: boolean,
  collectionName: string,
  collectionUrlNumber: string,
  handleGetInfoNFT: () => void,
  idx: string,
  image?: Image,
};

export const Info: FC<Props> = ({
  fetching,
  image,
  collectionName,
  collectionUrlNumber,
  handleGetInfoNFT,
  idx,
}) => {
  if (fetching) {
    return (
      <div className={styles.noData}>
        Loading
      </div>
    );
  }
  if (!image) {
    return (
      <div className={styles.noData}>
        Wrong URL
      </div>
    );
  }

  const { account } = useWeb3React();

  return (
    <div className={styles.info}>
      <div className={styles.container}>
        <div className={styles.mainInfo}>
          <div className={styles.imgContainer}>
            <img
              className={styles.img}
              src={`${HOST}/${collectionName}/img/${collectionUrlNumber}${idx}.png`}
              alt={`#${idx}`}
            />
          </div>
          <div className={styles.mainInfoContainer}>
            <div className={styles.mainInfoTop}>
              <h5 className={styles.name}>
                {`${collectionNames[collectionName || 'punks']} #${idx}`}
              </h5>
              <p className={styles.subinfo}>
                {image.collection ? collectionNumbers[image.collection] : image.type}
              </p>
              <div className={styles.tagsRow}>
                {image.attributes?.map((tag) => {
                  let tagType = tag;
                  let tagValue = tag;
                  if (typeof tag === 'object') {
                    tagType = tag?.trait_type;
                    tagValue = tag?.value;
                  }
                  return (
                    <Tag key={tagType + tagValue} text={tagValue} />
                  );
                })}
              </div>
            </div>
            <div className={styles.mainInfoBottom}>
              {image.rank ? (
                <p className={styles.rank}>
                  {`Rank: ${image.rank}`}
                </p>
              ) : null}

              {
                image.is_for_sale && image.owner === account && (
                  <Withdraw idx={idx} image={image} handleGetInfoNFT={handleGetInfoNFT} />
                )
              }

              {
                !image.is_for_sale && image.owner === account && <Offer idx={idx} handleGetInfoNFT={handleGetInfoNFT} />
              }

              {
                image.is_for_sale && image.owner !== account && (
                  <Buy idx={idx} image={image} handleGetInfoNFT={handleGetInfoNFT} />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import styles from './styles.module.scss';
import { Card } from './card';
import { Images } from './images';

export const Gallery: FC = () => (
  <div className={styles.container}>
    {Images.map(({ title, image, description }) => (
      <Card title={title} image={image} description={description} key={title} />
    ))}
  </div>
);

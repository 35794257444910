import React, { FC, useState } from 'react';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import { useContract } from 'hooks';
import { Button } from 'components';
import { useWeb3React } from '@web3-react/core';
import styles from './styles.module.scss';
import { ReactComponent as Binance } from '../../../../assets/images/logo/binance.svg';
import { Image } from '../interface';

type Props = {
  image: Image,
  idx: string,
  handleGetInfoNFT: () => void,
};

export const Withdraw: FC<Props> = ({ image, idx, handleGetInfoNFT }) => {
  const { contractDex } = useContract();
  const [isLoader, setLoader] = useState<boolean>(false);

  const { account } = useWeb3React();

  const handleWithdraw = async () => {
    try {
      setLoader(true);
      const estimateGas = await contractDex?.methods?.punkNoLongerForSale(idx)
        .estimateGas({ from: account });

      await contractDex?.methods?.punkNoLongerForSale(idx).call({ from: account, gas: estimateGas });
      setLoader(false);
      toast.success('Withdraw successful');
      handleGetInfoNFT();
    } catch (e) {
      console.log(e);
      setLoader(false);
      toast.error('Withdraw reject');
    }
  };

  return (
    <>
      <div className={styles.priceContainer}>
        <p className={styles.price}>
          {image.is_for_sale ? Web3.utils.fromWei(image?.offer?.min_value || '') : image.value}
        </p>
        <Binance className={styles.priceIcon} />
      </div>
      <Button
        loading={isLoader}
        className={styles.buyButton}
        onClick={handleWithdraw}
      >
        WITHDRAW
      </Button>
    </>
  );
};

import React, { useEffect, useRef, FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Title, ButtonMint } from 'components';
import { ROUTES } from 'constants/routes';
import { useDimensions } from 'hooks';

import logo from 'assets/images/lending/buyCrosscar/logo.png';
import carL from 'assets/images/lending/buyCrosscar/carL.png';
import carR from 'assets/images/lending/buyCrosscar/carR.png';
import carNameL from 'assets/images/lending/buyCrosscar/carNameL.png';
import carNameR from 'assets/images/lending/buyCrosscar/carNameR.png';
import VS from 'assets/images/lending/buyCrosscar/VS.png';

import styles from './styles.module.scss';

export const BuyCrosscar: FC = () => {
  const wrapRef = useRef<HTMLDivElement | null>(null);
  const logoRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDimensions();

  const handlerPosition = (e) => {
    if (!wrapRef.current || !logoRef.current) return;
    const offsetWidth = wrapRef.current?.offsetWidth;
    const halfWidth = offsetWidth / 2;

    const width = 25 / offsetWidth;

    const pageXRight = e.pageX - halfWidth;
    const pageXLeft = -e.pageX + halfWidth;

    const valueXRight = width * pageXRight * -1 - 10;
    const valueXLeft = width * pageXLeft * -1 - 10;

    const newvalueX = e.pageX > halfWidth ? valueXRight * (offsetWidth / 100) : -valueXLeft * (offsetWidth / 100);

    logoRef.current.style.transform = `translateX(${newvalueX}px)`;
  };

  useEffect(() => {
    if (width <= 768) return;
    if (wrapRef) {
      wrapRef.current?.addEventListener('mousemove', handlerPosition);
      // eslint-disable-next-line consistent-return
      return () => {
        wrapRef.current?.removeEventListener('mousemove', handlerPosition);
      };
    }
  }, [wrapRef, width]);

  const handleDefaultPositionLogo = () => {
    if (logoRef.current) {
      logoRef.current.style.transform = 'translateX(0px)';
    }
  };

  return (
    <div
      className={styles.container}
      ref={wrapRef}
      onMouseLeave={handleDefaultPositionLogo}
    >
      <Title className={styles.title} size="l">
        BUY YOUR
        {' '}
        <span className={styles.subtitle}>
          CROSSCAR
        </span>
        {' '}
        NOW
      </Title>

      <div className={styles.carContainer}>
        <div className={cn(styles.carWrapper, styles.carWrapperL)}>
          <Link to={`${ROUTES.mint}/1`} className={styles.linkCar}>
            <img className={styles.carL} src={carL} alt="car" />
            <img className={cn(styles.carName, styles.carNameL)} src={carNameL} alt="name" />
          </Link>
        </div>

        <div className={cn(styles.carWrapper, styles.carWrapperR)}>
          <Link to={`${ROUTES.mint}/2`} className={styles.linkCar}>
            <img className={styles.carR} src={carR} alt="car" />
            <img className={cn(styles.carName, styles.carNameR)} src={carNameR} alt="name" />
          </Link>
        </div>

        <div className={styles.vsContainer}>
          <img className={styles.VS} src={VS} alt="vs" />

          <h3 className={styles.text}>
            CHOOSE
            <br />
            YOUR SIDE
          </h3>
        </div>
      </div>

      <Link to={ROUTES.mint} className={styles.button}>
        <ButtonMint>
          MINT NOW!
        </ButtonMint>
      </Link>

      <div
        ref={logoRef}
        className={styles.logoWrap}
       >
        <img className={styles.logo} src={logo} alt="logo" />
      </div>
    </div>
  );
};

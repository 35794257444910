import React, { Dispatch, FC, useState } from 'react';

import { Input, ButtonFilter, Button } from 'components';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  onSortById: Dispatch<string>;
  onSortByRank: Dispatch<string>;
  onSortByPrice: Dispatch<string>;
  className?: string;
  setSearch: Dispatch<string>,
  type: string,
  search: string,
};

export const Filter: FC<Props> = ({
  onSortById,
  onSortByRank,
  onSortByPrice,
  type,
  search,
  setSearch,
}) => {
  const [isOpenSort, setOpenSort] = useState<boolean>(false);

  const [arrowId, setArrowId] = useState<boolean | null>(null);
  const [arrowRank, setArrowRank] = useState<boolean | null>(null);
  const [arrowPrice, setArrowPrice] = useState<boolean | null>(null);

  return (
    <div className={styles.filterWrap}>
      <div className={styles.filter}>
        <Input
          className={styles.search}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Search"
          type="search"
        />

        {
          type === 'punks' ? (
            <Button
              className={styles.select}
              Icon={
                <FilterIcon />
              }
              onClick={() => setOpenSort(!isOpenSort)}
            >
              FILTER
            </Button>
          ) : null
        }
      </div>

      {
        type === 'punks' ? (
          <div className={cn(styles.sortWrap, { [styles.sortOpen]: isOpenSort })}>
            <div className={styles.sort}>
              <ButtonFilter
                arrow={arrowId}
                setArrow={setArrowId}
                className={styles.sortButton}
                onClick={(value) => {
                  onSortById(value);
                  if (typeof arrowRank !== null) {
                    setArrowRank(null);
                  }
                  if (typeof arrowPrice !== null) {
                    setArrowPrice(null);
                  }
                }}
              >
                sort by id
              </ButtonFilter>
              <ButtonFilter
                arrow={arrowRank}
                setArrow={setArrowRank}
                className={styles.sortButton}
                onClick={(value) => {
                  onSortByRank(value);
                  if (typeof arrowId !== null) {
                    setArrowId(null);
                  }
                  if (typeof arrowPrice !== null) {
                    setArrowPrice(null);
                  }
                }}
              >
                sort by rank
              </ButtonFilter>
              <ButtonFilter
                arrow={arrowPrice}
                setArrow={setArrowPrice}
                className={styles.sortButton}
                onClick={(value) => {
                  onSortByPrice(value);
                  if (typeof arrowRank !== null) {
                    setArrowRank(null);
                  }
                  if (typeof arrowId !== null) {
                    setArrowId(null);
                  }
                }}
              >
                sort by price
              </ButtonFilter>
            </div>
          </div>
        ) : null
      }
    </div>
  );
};

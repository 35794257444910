import React, { FC } from 'react';
import cn from 'classnames';

import planet from 'assets/images/lending/roadmap/planet.png';
import clouds from 'assets/images/lending/roadmap/clouds.png';

import styles from './styles.module.scss';

type Props = {
  isNext: boolean,
  isPrev: boolean,
};

export const Planet: FC<Props> = ({ isNext, isPrev }) => (
  <div className={styles.container}>

    <div className={cn(styles.planetWrap, { [styles.rotateRight]: isNext, [styles.rotateLeft]: isPrev })}>
      <img
        src={planet}
        alt="planet"
        className={styles.planet}
      />
    </div>

    <div className={cn(styles.cloudsWrap, { [styles.rotateLeft]: isNext, [styles.rotateRight]: isPrev })}>
      <img
        src={clouds}
        alt="clouds"
        className={styles.clouds}
      />
    </div>
  </div>
);

import React, { FC } from 'react';
import { useDimensions } from 'hooks';
import { Button, Text, Title } from 'components';
import { Link } from 'react-router-dom';
import collection from '../../../assets/images/lending/theCollection/collection_full.png';
import collectionSmall from '../../../assets/images/lending/theCollection/collection_small.png';
import { ROUTES } from '../../../constants';

import styles from './styles.module.scss';

export const TheCollection: FC = () => {
  const { width } = useDimensions();

  let image: string;
  if (width <= (+styles.mobile)) {
    image = collectionSmall;
  } else {
    image = collection;
  }

  return (
    <div className={styles.container}>
      <Title className={styles.title} size="l">
        THE
        {' '}
        <span className={styles.titleBold}>
          COLLECTION
        </span>
      </Title>

      <div className={styles.wrapper}>
        <div className={styles.imgWrap}>
          <img src={image} alt="collection" />
        </div>
        <div>
          <Text className={styles.text1}>
            Waking up from a long space journey and first reaching for a cold
            one- we realized that the best entertainment in the vast wastelands
            of a new planet was a crazy genre-bending cocktail of racing and cards!
          </Text>

          <Text className={styles.text1}>
            It immediately became obvious that there would be CrossCars! We were inspired
            by popular and loved films and games, so keep your eyes open for easter eggs.
            In the new collection (first season) you will find 20 thousand fighting
            monsters of two factions, with a unique set of attributes that will
            directly affect the gameplay. Choose a faction that is closer to you
            and collect a garage of cars to keep the power in balance (well, and get closer to victory, of course).
          </Text>

          <Text className={styles.text2}>
            Don't forget about Punks themselves as your pass to the PvP world. And to the crosschain NFTs, of course!
          </Text>
          <div className={styles.buttonWrap}>
            <Link to={ROUTES.marketplace} className={styles.link}>
              <Button className={styles.button}>
                GO TO MARKETPLACE
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Tag,
} from 'components';
import { HOST } from 'constants/api';

import styles from './styles.module.scss';
import { Image } from '../interface';

const collectionNames = {
  punks: 'CrossPunk',
  cars: 'CrossCar',
};

const collectionNumbers = {
  1: 'TechnoManiacs',
  2: 'Awokensages',
};

export const Info = () => {
  const { collectionName, idx, collectionNumber } = useParams();

  const [image, setImage] = useState<Image | null>(null);
  const [, setError] = useState('');
  const [fetching, setFetching] = useState(true);

  const collectionUrlNumber = collectionNumber ? `${collectionNumber}/` : '';

  const getImage = useCallback(() => {
    setFetching(true);
    if (!collectionName || !idx) {
      setFetching(false);
      return setError('Something went wrong!');
    }
    return fetch(`${HOST}/${collectionName}/${collectionUrlNumber}${idx}`)
      .then((data) => data.json())
      .then((imageResult) => {
        setImage(imageResult);
      })
      .catch(() => {
        setError('Something went wrong! ');
      })
      .finally(() => setFetching(false));
  }, []);

  useEffect(() => {
    getImage();
  }, []);

  if (fetching) {
    return (
      <div>
        Loading
      </div>
    );
  }
  if (!image) {
    return (
      <div>
        Wrong URL
      </div>
    );
  }

  return (
    <div className={styles.info}>
      <div className={styles.container}>
        <div className={styles.mainInfo}>
          <div className={styles.imgContainer}>
            <img
              className={styles.img}
              src={`${HOST}/${collectionName}/img/${collectionUrlNumber}${idx}.png`}
              alt={`#${idx}`}
            />
          </div>
          <div className={styles.mainInfoContainer}>
            <div className={styles.mainInfoTop}>
              <h5 className={styles.name}>
                {`${collectionNames[collectionName || 'punks']} #${idx}`}
              </h5>
              <p className={styles.subinfo}>
                {image.collection ? collectionNumbers[image.collection] : image.type}
              </p>
              <div className={styles.tagsRow}>
                {image.attributes?.map((tag) => {
                  let tagType = tag;
                  let tagValue = tag;
                  if (typeof tag === 'object') {
                    tagType = tag?.trait_type;
                    tagValue = tag?.value;
                  }
                  return (
                    <Tag key={tagType + tagValue} text={tagValue} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { useTable } from 'react-table';

import { ReactComponent as Binance } from 'assets/images/logo/binance.svg';
import {
  Title,
} from 'components';
import Web3 from 'web3';
import { InfoNFT } from '../interface';

import styles from './styles.module.scss';

type Props = {
  data: InfoNFT['history'];
};

export const History: FC<Props> = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Price',
        accessor: 'bnb_price',
        Cell: ({ cell: { value } }) => (
          <div className={styles.price}>
            <Binance className={styles.icon} />
            <b>
              {Web3.utils.fromWei(value || '')}
            </b>
            &nbsp;
            BNB
          </div>
        ),
      },
      {
        Header: 'USD Price',
        accessor: 'usd_price',
      },
      {
        Header: 'Floor Difference',
        accessor: 'floor',
        Cell: ({ cell: { value } }) => (
          value ? (
            <div>
              {value}
              %
              {' '}
              below
            </div>
          ) : 'Floor price'
        ),
      },
      {
        Header: 'Expiration',
        accessor: 'created_at',
        Cell: ({ cell: { value } }) => (
          <div>
            {value}
            {' '}
            days a go
          </div>
        ),
      },
      {
        Header: 'From',
        accessor: 'from',
        Cell: ({ cell: { value } }) => (
          <div>
            {value.slice(0, 5)}
            ...
          </div>
        ),
      },
      {
        Header: 'To',
        accessor: 'to',
        Cell: ({ cell: { value } }) => (
          <div>
            {value.slice(0, 5)}
            ...
          </div>
        ),
      },
      {
        Header: 'Tx',
        accessor: 'tx',
        className: styles.from,
        Cell: ({ cell: { value } }) => (
          <a href={`https://bscscan.com/tx/${value}`} target="_blank">
            {value.slice(0, 5)}
            ...
          </a>
        ),
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div className={styles.info}>
      <div className={styles.container}>
        <Title className={styles.title}>
          HISTORY
        </Title>
        <div className={styles.tableWrapper}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps([
                        {
                          className: cell.column.className,
                        },
                      ])}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

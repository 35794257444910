import React, { FC, Dispatch } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import { ReactComponent as ArrowRight } from 'assets/images/lending/roadmap/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/images/lending/roadmap/arrowLeft.svg';
import { useDimensions } from 'hooks';
import { SLIDES } from './slides';

import styles from './styles.module.scss';

const NextArrow = ({ onClick, onClickNext, isDisabled }) => (
  <button
    disabled={isDisabled}
    onClick={() => {
      onClick();
      onClickNext();
    }}
    className={styles.button}>
    <ArrowRight />
  </button>
);

const PrevArrow = ({ onClick, onClickPrev, isDisabled }) => (
  <button
    disabled={isDisabled}
    onClick={() => {
      onClick();
      onClickPrev();
    }}
    className={styles.button}
    >
    <ArrowLeft />
  </button>
);

type Props = {
  className?: string,
  onClickNext: Dispatch<boolean>
  onClickPrev: Dispatch<boolean>
};

export const Carousel: FC<Props> = ({
  onClickNext,
  onClickPrev,
  className,
}) => {
  const { width } = useDimensions();

  let tabletSettings = {};
  if (width <= (+styles.mobile)) {
    tabletSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
    };
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    // @ts-ignore
    prevArrow: <PrevArrow onClickPrev={onClickPrev} />,
    // @ts-ignore
    nextArrow: <NextArrow onClickNext={onClickNext} />,
    arrows: true,
    ...tabletSettings,
  };

  return (
    <div className={styles.containerWrap}>
      <div className={cn(styles.container, className)}>
        <div className={styles.wrap}>
          <Slider {...settings}>
            {
              SLIDES.map(({ name, img }) => (
                <div className={styles.logoWrap} key={name}>
                  <img src={img} alt={name} className={styles.logo} />
                </div>
              ))
            }
          </Slider>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { MediaImg } from 'components';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { ReactComponent as Logo } from '../../assets/images/logo/logo.svg';

import styles from './styles.module.scss';

export const Footer = () => (
  <footer className={styles.footer}>
    <h4 className={styles.text}>
      Limitation of Liability
    </h4>
    <Link to={ROUTES.root}>
      <Logo className={styles.logo} />
    </Link>
    <MediaImg />
  </footer>
);

import React, {
  Dispatch, FC, PropsWithChildren,
} from 'react';

import { Button } from 'components';
import { ReactComponent as ArrowSort } from 'assets/icons/arrowSort.svg';

import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  onClick: Dispatch<string>;
  setArrow: Dispatch<boolean | null>;
  arrow: boolean | null;
  className?: string;
};

export const ButtonFilter: FC<PropsWithChildren<Props>> = ({
  onClick,
  className,
  arrow,
  setArrow,
  children,
}) => {
  const handleOnClick = () => {
    if (arrow === null) {
      setArrow(true);
      onClick('asc');
    }
    if (arrow) {
      setArrow(false);
      onClick('desc');
    }
    if (typeof arrow === 'boolean' && !arrow) {
      setArrow(null);
      onClick('');
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <Button
        Icon={
          <ArrowSort
            className={cn(
              styles.arrow,
              { [styles.arrowDown]: arrow },
              { [styles.arrowUp]: typeof arrow === 'boolean' && !arrow },
            )}
          />
        }
        className={styles.sortButton}
        onClick={handleOnClick}
      >
        {children}
      </Button>
    </div>
  );
};

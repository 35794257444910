import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { Button } from 'components';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { ReactComponent as Logo } from '../../assets/images/logo/logo.svg';
import { Menu } from './Menu';
import { ROUTES, MENU_DATA } from '../../constants';

import styles from './styles.module.scss';

type Props = {
  active: Web3ReactContextInterface['active'];
  connect: () => void;
};

export const Header = ({
  active,
  connect,
}: Props) => {
  const location = useLocation();
  const web3 = useWeb3React();

  const handleSwitchChainId = async () => {
    try {
      // @ts-ignore
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
      });
    } catch (e) {
      toast.error('The wrong network. Please select BSC');
      console.log(e);
    }
  };

  useEffect(() => {
    if (web3.chainId && web3.chainId !== 56) {
      handleSwitchChainId();
    }
  }, [web3.chainId]);

  const links = MENU_DATA.map(({ label, value }) => (
    <Link
      key={label}
      to={value}
      className={cn(
        styles.link,
        { [styles.linkActive]: location.pathname === value },
      )}
    >
      {label}
    </Link>
  ));

  return (
    <header className={styles.headerWrapper}>
      <div className={styles.header}>

        <div className={styles.navigationWrapper}>
          <Link className={styles.logo} to={ROUTES.root}>
            <Logo className={styles.logoIcon} />
          </Link>

          <div className={styles.navigation}>
            {links}
          </div>

          <div className={styles.walletWrapper}>
            {active ?
              <Link to={ROUTES.mint} className={styles.walletAddress}>
                <Button>
                  MINT
                </Button>
              </Link> :
              <Button
                onClick={connect}
                className={styles.walletAddress}
              >
                Connect Wallet
              </Button>
            }
          </div>
        </div>

        <Menu className={styles.burger} />

      </div>

    </header>
  );
};

import { ROUTES } from './routes';

type Links = {
  value: string;
  label: string;
};

export const MENU_DATA: Links[] = [
  {
    label: 'Factory',
    value: ROUTES.factory,
  },
  {
    label: 'My Punks',
    value: ROUTES.myPunks,
  },
  {
    label: 'Gamefi',
    value: ROUTES.game,
  },
  {
    label: 'Gallery',
    value: ROUTES.gallery,
  },
  {
    label: 'Marketplace',
    value: ROUTES.marketplace,
  },
  {
    label: 'Blog',
    value: ROUTES.blog,
  },
  {
    label: 'Bridge',
    value: ROUTES.bridge,
  },
  // {
  //   label: 'Contract',
  //   value: ROUTES.contract,
  // },
];

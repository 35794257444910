import React from 'react';

import { ContractInfo } from 'components';

import cp from 'assets/icons/contracts/cp.png';
import cst from 'assets/icons/contracts/cst.png';
import fuel from 'assets/icons/contracts/fuel.png';
import staking from 'assets/icons/contracts/staking.png';
import cpa from 'assets/icons/contracts/cpa.png';
import cpt from 'assets/icons/contracts/cpt.png';
import ci from 'assets/icons/contracts/ci.png';
import stakingItem from 'assets/icons/contracts/staking-item.png';

import styles from './styles.module.scss';

const contracts = [
  {
    icon: cp,
    name: 'CrossPunks NFT (CP)',
    address: '0x360673A34cf5055DfC22C53bc063e948A243293B',
  },
  {
    icon: cst,
    name: 'CrossToken (CST)',
    address: '0x014be200c192bD8366dF781a96cec51B3D9Dcd93',
  },
  {
    icon: fuel,
    name: 'FuelToken (FUEL)',
    address: '0xF4d61DEa94EddA4A2b3b469AF4313f0D499C86C9',
  },
  {
    icon: staking,
    name: 'Staking contract',
    address: '0xD16ba5a716E1338e8328B8aC7e2896cf32313E46',
  },
  {
    icon: cpa,
    name: 'CrossCar «Awokensages» NFT (CPA)',
    address: '0x360673A34cf5055DfC22C53bc063e948A243293B',
  },
  {
    icon: cpt,
    name: 'CrossCar «Technomaniacks» NFT (CPT)',
    address: '0x014be200c192bD8366dF781a96cec51B3D9Dcd93',
  },
  {
    icon: ci,
    name: 'CrossItem (CI)',
    address: '0x014be200c192bD8366dF781a96cec51B3D9Dcd93',
  },
  {
    icon: stakingItem,
    name: 'Staking Item contract',
    address: '0x014be200c192bD8366dF781a96cec51B3D9Dcd93',
  },
];

export const Contract = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>
      Contracts in the BSC network
    </h3>
    <div className={styles.contracts}>
      {contracts.map(({
        icon,
        name,
        address,
      }) => (
        <div key={name}>
          <ContractInfo
            className={styles.contract}
            icon={icon}
            name={name}
            address={address}
          />
        </div>
      ))}
    </div>
  </div>
);

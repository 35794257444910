import { useState } from 'react';
import { useContract } from 'hooks';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import { approve } from 'utils';
import { BSC_MAINNET_PINK_ADDRESS, BSC_MAINNET_VIOLET_ADDRESS } from '../constants';

type Methods = {
  isLoading: boolean,
  mint: () => Promise<void> | void,
};

export const useMint = (type: 'pink' | 'violet', count: number): Methods => {
  const { contractPink, contractViolet, contractBUSD } = useContract();
  const { account } = useWeb3React();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  let mint = () => {};

  const handleMintPink = async () => {
    try {
      setIsLoading(true);
      const publicSalePrice = await contractPink?.methods?.getNFTPrice().call();
      const counterBN = Web3.utils.toBN(count.toString());
      const totalAmount = counterBN.mul(Web3.utils.toBN(publicSalePrice));

      await approve({
        account,
        totalAmount,
        address: BSC_MAINNET_PINK_ADDRESS,
        contract: contractBUSD,
      });

      // const estimateGas = await contractPink?.methods?.mintNFT(count)
      //   .estimateGas({ from: account });

      await contractPink?.methods?.mintNFT(count).send({ from: account, gas: 400000 * count });

      toast.success('Mint successful');
      setIsLoading(false);
    } catch (e) {
      toast.error('Mint rejected');
      setIsLoading(false);
    }
  };

  const handleMintViolet = async () => {
    try {
      setIsLoading(true);
      const publicSalePrice = await contractViolet?.methods?.getNFTPrice().call();

      const counterBN = Web3.utils.toBN(count.toString());
      const totalAmount = counterBN.mul(Web3.utils.toBN(publicSalePrice));

      await approve({
        account,
        totalAmount,
        address: BSC_MAINNET_VIOLET_ADDRESS,
        contract: contractBUSD,
      });

      // const estimateGas = await contractViolet?.methods?.mintNFT(count)
      //   .estimateGas({ from: account });

      await contractViolet?.methods?.mintNFT(count).send({ from: account, gas: 400000 * count });

      toast.success('Mint successful');
      setIsLoading(false);
    } catch (e) {
      toast.error('Mint rejected');
      setIsLoading(false);
    }
  };

  switch (type) {
    case 'pink': {
      mint = handleMintPink;
      break;
    }

    case 'violet': {
      mint = handleMintViolet;
      break;
    }

    default: {
      console.log('no type');
    }
  }

  return {
    isLoading,
    mint,
  };
};

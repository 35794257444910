import React, {
  useCallback,
  useEffect,
  useReducer, useState,
} from 'react';
import { Carousel } from './Carousel';
import { Filter } from './Filter';
import { CurrentFilters } from './CurrentFilters';
import { List } from './List';

import styles from './styles.module.scss';

const imgReducerInitial = {
  images: [],
  fetching: false,
  total: 12,
  collectionName: 'punks',
  collectionNumber: null,
  sort: { by: null, order: '' },
};

const imgReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IMAGES':
      return {
        ...state,
        images: action.images,
        total: action.total,
        fetching: action.fetching,
      };
    case 'STACK_IMAGES':
      return {
        ...state,
        images: state.images.concat(action.images),
        total: action.total,
        fetching: action.fetching,
      };
    case 'FETCHING_IMAGES':
      return { ...state, fetching: action.fetching };
    case 'SET_COLLECTION':
      return {
        ...imgReducerInitial,
        images: [],
        collectionName: action.name,
        collectionNumber: action.number,
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: action.sort,
      };
    default:
      return state;
  }
};

const host = 'https://api.crosspunks.com';

export const Marketplace = () => {
  const [imagesState, imgDispatch] = useReducer(
    imgReducer,
    imgReducerInitial,
  );

  const [search, setSearch] = useState<string>('');

  const handleSort = (type: string | null, order: string) => {
    imgDispatch({ type: 'SET_SORT', sort: { by: type, order } });
  };

  const getImages = useCallback((isStackImage?: boolean) => {
    imgDispatch({ type: 'FETCHING_IMAGES', fetching: true });
    const offset = isStackImage ? imagesState.images.length : 0;
    let limit = 12;
    if (isStackImage && offset + limit >= imagesState.total) limit = imagesState.total - offset;
    // eslint-disable-next-line max-len
    fetch(`${host}/${imagesState.collectionName}?limit=${limit}&offset=${offset}&is_for_sale=true&sort_by=${imagesState.sort.by}&order=${imagesState.sort.order}&index=${search}`)
      .then((data) => data.json())
      .then((imagesResult) => {
        imgDispatch({
          type: isStackImage ? 'STACK_IMAGES' : 'SET_IMAGES',
          images: imagesResult[imagesState.collectionName],
          fetching: false,
          total: imagesResult.total || imagesState.total,
        });
      })
      .catch((e) => {
        imgDispatch({ type: 'FETCHING_IMAGES', fetching: false });
        return e;
      });
  }, [imagesState, search]);

  useEffect(() => {
    getImages();
  }, [imagesState.collectionName, imagesState.collectionNumber, imagesState.sort, search]);
  return (
    <div className={styles.container}>
      <Carousel
        afterChange={() => {
          // if (current === 1 && imagesState.collectionNumber === null) return;
          // if (current === 0 && imagesState.collectionNumber === 1) return;
          // if (current === 2 && imagesState.collectionNumber === 2) return;

          // if (current === 0 || current === 2) {
          //   imgDispatch({ type: 'SET_COLLECTION', name: 'cars', number: !current ? 1 : 2 });
          //   return;
          // }
          // imgDispatch({ type: 'SET_COLLECTION', name: 'punks', number: null });
        }}
      />
      <Filter
        onSortById={(sort) => handleSort(sort ? 'index' : null, sort)}
        onSortByRank={(sort) => handleSort(sort ? 'rank' : null, sort)}
        onSortByPrice={(sort) => handleSort(sort ? 'min_value' : null, sort)}
        search={search}
        setSearch={setSearch}
        type={imagesState.collectionName}
      />
      <CurrentFilters />
      <List
        getImages={getImages}
        images={imagesState.images}
        total={imagesState.total}
        collection={{ name: imagesState.collectionName, number: imagesState.collectionNumber }}
      />
    </div>
  );
};

import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import cn from 'classnames';

import { MENU_DATA } from 'constants/menuData';
import cross from 'assets/icons/header/cross.svg';
import burger from 'assets/icons/header/burger.svg';
import { MediaImg } from 'components';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

export const Menu: FC<Props> = ({ className }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <div className={cn(styles.container, className)}>
      <button onClick={() => setOpen(!isOpen)} className={styles.burger}>
        <img src={isOpen ? cross : burger} />
      </button>

      <div className={cn(styles.menu, { [styles.open]: isOpen })}>
        <ul className={styles.linkList}>
          {MENU_DATA.map(({ label, value }) => (
            <li key={label} className={cn(styles.listItem, { [styles.listItemShow]: isOpen })}>
              <Link to={value} className={styles.link} onClick={() => setOpen(false)}>
                {label}
              </Link>
            </li>
          ))}
        </ul>

        <div className={styles.buttonWrap}>
          <MediaImg />
        </div>
      </div>
    </div>
  );
};

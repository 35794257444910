import * as React from 'react';
import { useDimensions } from 'hooks';
import cn from 'classnames';
import styles from './styles.module.scss';

export const StepperChain = () => {
  const { width } = useDimensions();

  const red = '#FF0235';

  const activeStepId = 2;

  const steps = [
    {
      id: 1,
      label: 'CrossCars collection drop - The 1st season',
    },
    {
      id: 2,
      label: 'FUEL utility and ERC-1155 items introduction',
    },
    {
      id: 3,
      label: 'Polkadot Testnet launch with NFT modules',
    },
    {
      id: 4,
      label: 'Voting for a new chain and building bridges',
    },
    {
      id: 5,
      label: 'PvP game beta release',
    },
    {
      id: 6,
      label: 'Factions and leaderboards introduction',
    },
    {
      id: 7,
      label: 'Polkadot Mainnet, Substrate-based token emission and listing',
    },
    {
      id: 8,
      label: 'Polkadot wallet integration',
    },
    {
      id: 9,
      label: 'Parachain or Parathreads selection',
    },
    {
      id: 10,
      label: 'Fullscale Gamefi release',
    },
    {
      id: 11,
      label: 'New NFTs types development',
    },
  ];

  function firstElement(step) {
    return (
      <>
        <div className={styles.empty} />
        <div className={styles.title}>
          <div
            className={cn(styles.number, styles.firstNumber)}
            style={ step.id <= activeStepId ? { color: red, borderColor: red } : { } }
          >
            {step.id}
          </div>
        </div>
        <div className={styles.label}>
          {step.label}
        </div>
      </>
    );
  }

  function secondElement(step) {
    return (
      <>
        <div className={styles.label} style={ { textAlign: 'end' } }>
          {step.label}
        </div>
        <div className={styles.title}>
          <div
            className={cn(styles.number, styles.secondNumber)}
            style={ step.id <= activeStepId ? { color: red, borderColor: red } : { } }
          >
            {step.id}
          </div>
        </div>
        <div className={styles.empty} />
      </>
    );
  }

  if (width <= (+styles.tablet)) {
    return (
      <div className={styles.container}>
        {steps.map((step) => (
          <div key={step.id} className={styles.step}>
            { firstElement(step)}
            { step.id === steps.length ? null :
            <div className={styles.divider} style={ step.id < activeStepId ? { borderColor: red } : { } } />}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {steps.map((step) => (
        <div key={step.id} className={styles.step}>
          { step.id % 2 === 0 ? secondElement(step) : firstElement(step)}
          { step.id === steps.length ? null :
          <div className={styles.divider} style={ step.id < activeStepId ? { borderColor: red } : { } } />}
        </div>
      ))}
    </div>
  );
};

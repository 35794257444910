import React, { FC } from 'react';
import { Text } from 'components';
import styles from './styles.module.scss';

type Props = {
  title: string,
  image: string,
  description: Array<string>,
};

export const Card: FC<Props> = ({ title, image, description }) => (
  <div className={styles.cardWrap}>
    <div className={styles.cardTitleWrap}>
      <Text className={styles.cardTitle}>
        {title}
      </Text>
    </div>
    <div className={styles.cardImageWrap}>
      <img src={image} className={styles.cardImage} alt="image" />
    </div>
    <div className={styles.cardTextWrap}>
      {description.map((el) => (
        <Text key={el} className={styles.cardText}>
          {el}
        </Text>
      ))}
    </div>
  </div>
);

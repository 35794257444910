import React, { FC, useState, Dispatch } from 'react';
import { Title } from 'components';

import { Carousel } from './Carousel';
import { Planet } from './Planet';

import styles from './styles.module.scss';

export const Partners: FC = () => {
  const [isNext, setNext] = useState<boolean>(false);
  const [isPrev, setPrev] = useState<boolean>(false);

  const handleSwitchAnimation = (cb: Dispatch<boolean>) => {
    cb(true);
    setTimeout(() => cb(false), 2000);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        <Title className={styles.title} size="l">
          PARTNERS
        </Title>
        <Carousel
          onClickNext={() => handleSwitchAnimation(setNext)}
          onClickPrev={() => handleSwitchAnimation(setPrev)}
        />
        <Planet isNext={isNext} isPrev={isPrev} />
      </div>
    </div>
  );
};

import { useState } from 'react';
import { useContract } from 'hooks';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import { approve } from 'utils';
import { BSC_MAINNET_STAKING_ADDRESS } from '../constants';
import bscBUSD from '../contracts/abi/bscBUSD.json';

type Methods = {
  isLoading: boolean,
  deposit: (amount: string) => Promise<void> | void,
};

export const useDeposit = (id: number, handleGetTokens: () => Promise<void> | void, address): Methods => {
  const { contractStaking, provider } = useContract();
  const { account } = useWeb3React();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deposit = async (amount: string) => {
    try {
      setIsLoading(true);

      const totalAmount = Web3.utils.toBN(Web3.utils.toWei(amount, 'ether'));

      const contract = new provider.eth.Contract(bscBUSD, address);

      await approve({
        account,
        totalAmount,
        address: BSC_MAINNET_STAKING_ADDRESS,
        contract,
      });

      const estimateGas = await contractStaking?.methods?.deposit(id, totalAmount)
        .estimateGas({ from: account });

      await contractStaking?.methods?.deposit(id, totalAmount).send({ from: account, gas: estimateGas });

      toast.success('Deposit successful');
      setIsLoading(false);
      handleGetTokens();
    } catch (e) {
      toast.error('Deposit rejected');
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    deposit,
  };
};

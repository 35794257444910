import React, { FC } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';

import { useDimensions } from 'hooks';

import styles from './styles.module.scss';

interface Props {
  afterChange?: (current: number) => void,
}

export const Carousel: FC<Props> = ({
  afterChange,
}) => {
  const { width } = useDimensions();

  let tabletSettings = {};
  if (width >= +styles.tabletBig) {
    tabletSettings = {
      centerPadding: `${(width - 782) / 2}px`, // 20px added for padding from scroll
      focusOnSelect: true,
      centerMode: true,
    };
  }

  const settings = {
    ...tabletSettings,
    dots: true,
    infinite: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    afterChange,
    arrows: false,
    customPaging: () => (
      <div className={styles.dot}>
        <div className={styles.dotInner} />
      </div>
    ),
  };

  return (
    <div className={styles.carousel}>
      <Slider {...settings}>
        <div className={cn(styles.slideWrapper)}>
          <div className={cn(styles.slide, styles.slideTM)} />
        </div>
        <div className={cn(styles.slideWrapper)}>
          <div className={cn(styles.slide, styles.slideCP)} />
        </div>
        <div className={cn(styles.slideWrapper)}>
          <div className={cn(styles.slide, styles.slideAS)} />
        </div>
      </Slider>
    </div>
  );
};

import image1 from 'assets/images/gamefi/gamefi_02_1.png';
import image2 from 'assets/images/gamefi/gamefi_02_2.png';
import image3 from 'assets/images/gamefi/gamefi_02_3.png';

export const Images = [
  {
    title: 'Your battle fellah, a PunkCar',
    image: image1,
    description: ['Unique Combination of attributes', 'Multipliers based on attributes to boost up your cars'],
  },
  {
    title: 'A battle friend to survive a CrossCar',
    image: image2,
    description: ['Each attribute to use in game', 'Get new cars, uncover new elements get the most of your strategy'],
  },
  {
    title: 'Collect Cards to Play',
    image: image3,
    description: [
      'Stake in-game token get the FUEL unleash the power of craft',
      'Collect your deck to prevail',
      'Use tricky sets and combos',
    ],
  },
];

import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ButtonMint } from 'components';
import { ROUTES } from 'constants/routes';
// import image from '../../../assets/images/gamefi/marketplace.png';
import styles from './styles.module.scss';

export const Mint: FC = () => (
  <div className={styles.container}>
    <div className={styles.wrap}>
      <Link to={ROUTES.mint} className={styles.button}>
        <ButtonMint>
          MINT NOW!
        </ButtonMint>
      </Link>
    </div>
  </div>
);

import React, { FC } from 'react';
import { InputProps } from '@mui/material/Input';
import cn from 'classnames';
import { Button, InputAmount } from 'components';

import styles from './styles.module.scss';

type Props = {
  onChange: InputProps['onChange'],
  onBlur?: InputProps['onBlur'],
  value: string,
  classNameButton?: string,
  className?: InputProps['className'],
  placeholder?: InputProps['placeholder'],
  disabled?: InputProps['disabled'],
  name?: InputProps['name'],
  type?: 'search',
  buttonName: string,
  buttonDisabled?: boolean,
  isLoading?: boolean,
  onClick: () => void,
};

export const InputFactory: FC<Props> = ({
  className,
  classNameButton,
  placeholder = '',
  onChange,
  onBlur,
  value,
  type,
  disabled,
  name,
  buttonName,
  buttonDisabled,
  isLoading,
  onClick,
}) => (
  <div className={cn(styles.container, className)}>
    <InputAmount
      name={name}
      disabled={disabled}
      type={type}
      value={value}
      className={styles.cardSumNumb}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
    />
    <Button
      className={cn(styles.cardSumBtn, styles.cardSumBtnDisable, classNameButton)}
      disabled={buttonDisabled}
      loading={isLoading}
      onClick={onClick}
    >
      {buttonName}
    </Button>
  </div>
);

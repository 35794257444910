// Mainnet
export const BSC_MAINNET_PINK_ADDRESS = '0x7f3495cf2d05db6e9e52cdf989bced71e786725c';

export const BSC_MAINNET_VIOLET_ADDRESS = '0x691bd0f2f5a145fcf297cf4be79095b66f002cbc';

export const BSC_MAINNET_STAKING_ADDRESS = '0xD16ba5a716E1338e8328B8aC7e2896cf32313E46';

export const BUSD_MAINNET_ADDRESS = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';

export const MAINNET_DEX_ADDRESS = '0x36894d06ac91B09760b4310C75Ed2348E3eA063C';

// Testnet
export const BSC_TESTNET_PINK_ADDRESS = '';

export const BSC_TESTNET_VIOLET_ADDRESS = '';

export const BSC_TESTNET_STAKING_ADDRESS = '0xbf6a0a7733202a2628090056442a575633eff92e';

export const BUSD_TESTNET_ADDRESS = '';

import React, { FC } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';
import { useDimensions } from 'hooks';
import { Slide1, Slide2, Slide3 } from './Slides';
import styles from './styles.module.scss';

const NextArrow = ({ onClick, isDisabled }) => (
  <button
    disabled={isDisabled}
    onClick={() => {
      onClick();
    }}
    className={cn(styles.nextButton, styles.arrowBtn)} />
);

const PrevArrow = ({ onClick, isDisabled }) => (
  <button
    disabled={isDisabled}
    onClick={() => {
      onClick();
    }}
    className={cn(styles.prevButton, styles.arrowBtn)} />
);

export const Carousel: FC = () => {
  const { width } = useDimensions();

  let tabletSettings = {};
  if (width <= (+styles.tablet)) {
    tabletSettings = {
      arrows: false,
    };
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    // @ts-ignore
    prevArrow: <PrevArrow />,
    // @ts-ignore
    nextArrow: <NextArrow />,
    customPaging: () => (
      <div className={styles.dot}>
        <div className={styles.dotInner} />
      </div>
    ),
    ...tabletSettings,
  };

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        <Slide1 />
        <Slide2 />
        <Slide3 />
      </Slider>
    </div>
  );
};

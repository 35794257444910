import React, { FC } from 'react';
import { ButtonMint, Text } from 'components';
import { Link } from 'react-router-dom';
import { Banner } from './banner';
import styles from './styles.module.scss';
import { ROUTES } from '../../../constants';

export const Gamefi: FC = () => (
  <div className={styles.container}>
    <Banner />
    <div className={styles.textWrapper}>
      <Text className={styles.text}>
        With affection to card cames and classic arcades we have created a brand new experience to
        dive into!
      </Text>
      <Text className={styles.text}>
        Here we’ll tell what awaits you and how it’ll work.
      </Text>
      <Text className={styles.text}>
        First ever Card Racing game on blockchain! Behold Raging Cards! Turb-based pvp racing NFT game!
      </Text>
    </div>

    <Link to={ROUTES.mint} className={styles.button}>
      <ButtonMint>
        MINT NOW!
      </ButtonMint>
    </Link>
  </div>
);

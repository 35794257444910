import React, {
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';

import { Button, Loader } from 'components';
import { List } from './List';

import styles from './styles.module.scss';
import { HOST } from '../../constants/api';
import { Carousel } from '../Gallery/Carousel';

const LoadingWrapper = ({
  isLoading,
  children,
}) => {
  if (isLoading) return <Loader className={styles.loader} />;
  return children;
};

const imgReducerInitial = {
  images: [],
  fetching: false,
  total: 12,
  collectionName: 'punks',
  collectionNumber: null,
};

const imgReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IMAGES':
      return {
        ...state,
        images: action.images,
        total: action.total,
        fetching: action.fetching,
      };
    case 'STACK_IMAGES':
      return {
        ...state,
        images: state.images.concat(action.images),
        total: action.total,
        fetching: action.fetching,
      };
    case 'FETCHING_IMAGES':
      return { ...state, fetching: action.fetching };
    case 'SET_COLLECTION':
      return {
        ...imgReducerInitial,
        images: [],
        collectionName: action.name,
        collectionNumber: action.number,
      };
    default:
      return state;
  }
};

const ListWrapper = ({
  account,
}) => {
  const [imagesState, imgDispatch] = useReducer(
    imgReducer,
    imgReducerInitial,
  );

  const getImages = useCallback((isStackImage?: boolean) => {
    imgDispatch({ type: 'FETCHING_IMAGES', fetching: true });
    const offset = isStackImage ? imagesState.images.length : 0;
    let limit = 12;
    if (isStackImage && offset + limit >= imagesState.total) limit = imagesState.total - offset;
    fetch(`${HOST}/${imagesState.collectionName}?limit=${limit}&offset=${offset}&owner=${account}${
      typeof imagesState.collectionNumber === 'number' ?
        `&collection=${imagesState.collectionNumber}` :
        ''}`)
      .then((data) => data.json())
      .then((imagesResult) => {
        imgDispatch({
          type: isStackImage ? 'STACK_IMAGES' : 'SET_IMAGES',
          images: imagesResult[imagesState.collectionName],
          fetching: false,
          total: imagesResult.total || imagesState.total,
        });
      })
      .catch((e) => {
        imgDispatch({ type: 'FETCHING_IMAGES', fetching: false });
        return e;
      });
  }, [imagesState]);

  useEffect(() => {
    getImages();
  }, [imagesState.collectionName, imagesState.collectionNumber, account]);

  return (
    <>
      <Carousel
        afterChange={async (current) => {
          if (current === 1 && imagesState.collectionNumber === null) return;
          if (current === 0 && imagesState.collectionNumber === 1) return;
          if (current === 2 && imagesState.collectionNumber === 2) return;

          if (current === 0 || current === 2) {
            imgDispatch({ type: 'SET_COLLECTION', name: 'cars', number: !current ? 1 : 2 });
            return;
          }
          imgDispatch({ type: 'SET_COLLECTION', name: 'punks', number: null });
        }}
      />
      <LoadingWrapper isLoading={!imagesState.images.length && imagesState.fetching}>
        {!imagesState.total || !imagesState.images.length ? (
          <div className={styles.connect}>
            <h3 className={styles.connectText}>
              You haven't any tokens yet :(
            </h3>
          </div>
        ) : (
          <List
            getImages={getImages}
            images={imagesState.images}
            total={imagesState.total}
            collection={{ name: imagesState.collectionName, number: imagesState.collectionNumber }}
          />
        )}
      </LoadingWrapper>
    </>
  );
};

type Props = {
  account: Web3ReactContextInterface['account'];
  active: Web3ReactContextInterface['active'];
  connect: () => void;
  loading: boolean;
};

export const MyPunks = ({
  loading,
  account,
  active,
  connect,
}: Props) => (
  <div className={styles.container}>
    <LoadingWrapper isLoading={loading}>
      {(active || account) ? (
        <ListWrapper account={account} />
      ) : (
        <div className={styles.connect}>
          <h3 className={styles.connectText}>
            Connect your wallet to view your punks!
          </h3>
          <Button
            onClick={connect}
            className={styles.connectButton}
          >
            Connect Wallet
          </Button>
        </div>
      )}
    </LoadingWrapper>
  </div>
);

import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { Preloader } from 'components';
import { Info } from './Info';
import { History } from './History';
import { Extra } from './Extra';
import { HOST } from '../../../constants/api';

import styles from './styles.module.scss';
import { InfoNFT } from './interface';

export const PunksItem = () => {
  const [infoNFT, setInfoNFT] = useState<InfoNFT | null>(null);
  const [, setError] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(true);

  const { collectionName, idx, collectionNumber } = useParams();

  const collectionUrlNumber = collectionNumber ? `${collectionNumber}/` : '';

  const getInfoNFT = useCallback(() => {
    setFetching(true);
    if (!collectionName || !idx) {
      setFetching(false);
      return setError('Something went wrong!');
    }
    return fetch(`${HOST}/${collectionName}/${collectionUrlNumber}${idx}`)
      .then((data) => data.json())
      .then((imageResult) => {
        setInfoNFT(imageResult);
      })
      .catch(() => {
        setError('Something went wrong! ');
      })
      .finally(() => setFetching(false));
  }, [idx]);

  const handleGetInfoNFT = async () => {
    await setInfoNFT(null);
    await getInfoNFT();
  };

  useEffect(() => {
    handleGetInfoNFT();
  }, [idx]);

  return (
    <div className={styles.container}>
      <Preloader isLoading={fetching} className={styles.preloader}>
        <Info
          fetching={fetching}
          image={infoNFT?.punk}
          collectionName={collectionName || ''}
          idx={idx || ''}
          collectionUrlNumber={collectionUrlNumber || ''}
          handleGetInfoNFT={handleGetInfoNFT}
        />

        {
          infoNFT?.history && infoNFT?.history.length > 0 ? (
            <History data={infoNFT?.history} />
          ) : null
        }

        {
          infoNFT?.offer_punk && infoNFT?.offer_punk.length > 0 ? (
            <Extra
              images={infoNFT?.offer_punk}
              collectionName={collectionName || ''}
              collectionNumber={collectionNumber || ''}
            />
          ) : null
        }
      </Preloader>
    </div>
  );
};

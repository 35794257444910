import React, {
  useEffect,
  useState,
} from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { Header, Footer } from 'components';
import {
  Main,
  Factory,
  Marketplace,
  MyPunks,
  CarsItem,
  PunksItem,
  Gallery,
  Contract,
  Mint,
  Game,
  Blog,
  Bridge,
} from 'screens';
import { ROUTES } from './constants';
import { injected } from './wallet/Connect';

import styles from './styles.module.scss';

const App = () => {
  const web3 = useWeb3React();
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    async function load() {
      try {
        const isAuthorized = await injected.isAuthorized();
        if (isAuthorized && !web3.active && !web3.error) {
          setLoading(true);
          await web3.activate(injected);
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        setLoading(false);
      }
    }

    load();
  }, [web3]);

  async function connect() {
    try {
      await web3.activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  }

  useEffect(() => {
    connect();
  }, [web3.account]);

  useEffect(() => {
    const div = document.getElementById('main');
    if (!div) return;
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.main} id="main">
      <Header connect={connect} active={web3.active} />
      <Routes>
        <Route
          path={ROUTES.root}
          element={<Main />}
        />
        <Route
          path={ROUTES.factory}
          element={
            <Factory />
          }
         />
        <Route
          path={ROUTES.gallery}
          element={
            <Gallery />
          }
         />
        <Route
          path={ROUTES.myPunks}
          element={
            <MyPunks loading={loading} account={web3.account} connect={connect} active={web3.active} />
          }
        />
        <Route
          path={ROUTES.punksItem}
          element={
            <PunksItem />
          }
        />
        <Route
          path={ROUTES.carsItem}
          element={
            <CarsItem />
          }
        />
        <Route
          path={ROUTES.marketplace}
          element={
            <Marketplace />
          }
         />
        <Route
          path={ROUTES.contract}
          element={
            <Contract />
          }
        />
        <Route
          path={ROUTES.game}
          element={
            <Game />
          }
        />
        <Route
          path="/mint/*"
          element={
            <Mint />
          }
        />
        <Route
          path={ROUTES.blog}
          element={
            <Blog />
          }
        />
        <Route
          path={ROUTES.bridge}
          element={
            <Bridge />
          }
        />
        <Route
          path="*"
          element={<Navigate to={ROUTES.root} />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;

import React, { FC } from 'react';
import { Title } from 'components';
import styles from './styles.module.scss';

import logo from '../../../assets/images/logo/logo.svg';

export const Banner: FC = () => (
  <div className={styles.banner}>
    <img className={styles.logo} src={logo} alt='logo' />
    <Title className={styles.title} size="l">
      <span className={styles.titleBold}>
        GAME
      </span>
      {''}
      FI
    </Title>
  </div>
);

import logo1 from 'assets/images/lending/partners/logo1.png';
import logo2 from 'assets/images/lending/partners/logo2.png';
import logo3 from 'assets/images/lending/partners/logo3.png';

export const SLIDES = [
  {
    name: 'logo1',
    img: logo1,
  },
  {
    name: 'logo2',
    img: logo2,
  },
  {
    name: 'logo3',
    img: logo3,
  },
  {
    name: 'logo4',
    img: logo1,
  },
  {
    name: 'logo5',
    img: logo2,
  },
  {
    name: 'logo6',
    img: logo3,
  },
];

import React, { FC, useEffect, useState } from 'react';
import Web3 from 'web3';

import {
  Button,
  InputAmount,
  InputFactory,
  Loader,
} from 'components';

import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import { useContract, useDeposit, useWithdraw } from 'hooks';
import { BSC_MAINNET_STAKING_ADDRESS } from 'constants/hwAddress';
import bscBUSD from 'contracts/abi/bscBUSD.json';
import styles from './styles.module.scss';

type Props = {
  tokenImg1: string,
  tokenImg2: string,
  name: string,
  address: string,
  id: number,
};

export const FactoryItem: FC<Props> = ({
  name,
  tokenImg1,
  tokenImg2,
  address,
  id,
}) => {
  const { account } = useWeb3React();
  const [amountDeposit, setAmountDeposit] = useState<string>('');
  const [balanceOf, setBalanceOf] = useState<string>('');
  const [amountWithdraw, setAmountWithdraw] = useState<string>('');
  const [pendingReward, setPendingReward] = useState<string>('');
  const { contractStaking, provider } = useContract();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetTokens = async () => {
    setIsLoading(true);
    try {
      if (!account) return;
      const erc20 = new provider.eth.Contract(bscBUSD, address);

      const _balanceOf = await erc20?.methods?.balanceOf(BSC_MAINNET_STAKING_ADDRESS).call();

      const _pendingReward = await contractStaking?.methods?.pendingReward(id, account).call();

      const balanceOfFormatted = +Web3.utils.fromWei(_balanceOf, 'ether').toString();
      const pendingRewardFormatted = +Web3.utils.fromWei(_pendingReward, 'ether').toString();

      setPendingReward(pendingRewardFormatted.toFixed(2));
      setBalanceOf(balanceOfFormatted.toFixed(2));
      setIsLoading(false);
      setAmountWithdraw('');
      setAmountDeposit('');
    } catch (e) {
      setIsLoading(false);
      toast.error('Balance of reject');
      console.log(e);
    }
  };

  const { deposit, isLoading: isLoadingDeposit } = useDeposit(id, handleGetTokens, address);
  const { deposit: drainFuel, isLoading: isLoadingDrainFuel } = useDeposit(id, handleGetTokens, address);
  const { withdraw, isLoading: isLoadingWithdraw } = useWithdraw(id, amountWithdraw, handleGetTokens);

  useEffect(() => {
    handleGetTokens();
  }, [account]);

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <div className={styles.cardImg}>
          <img src={`data:image/jpeg;base64,${tokenImg1}`} alt="token" />
          {tokenImg2 && <img src={`data:image/jpeg;base64,${tokenImg2}`} alt="token" />}
        </div>
        <p className={styles.cardName}>
          {name}
        </p>
      </div>
      <p className={styles.colorSize}>
        FUEL EARNED
      </p>
      <div className={styles.cardSum}>
        {isLoading ? <Loader classNameWrap={styles.loader} /> : null}
        <InputFactory
          classNameButton={styles.buttonInput}
          buttonDisabled={!(+pendingReward > 0)}
          isLoading={isLoadingDrainFuel}
          disabled
          value={pendingReward}
          onChange={() => {}}
          buttonName="DRAIN FUEL"
          onClick={() => drainFuel('0')}
        />
      </div>
      <p className={styles.colorSize}>
        {name}
        &nbsp;STAKED
      </p>
      <div className={styles.stakedInputWrap}>
        {isLoading ? <Loader classNameWrap={styles.loader} /> : null}
        <InputAmount
          disabled
          value={balanceOf}
          onChange={() => {}}
        />
      </div>

      <div className={styles.cardSum}>
        <InputFactory
          name={name}
          value={amountDeposit}
          onChange={(e) => setAmountDeposit(e.target.value)}
          buttonName="DEPOSIT"
          onClick={() => deposit(amountDeposit)}
          isLoading={isLoadingDeposit}
        />
      </div>

      <div className={styles.cardSum}>
        <InputFactory
          classNameButton={styles.buttonInput}
          onClick={withdraw}
          isLoading={isLoadingWithdraw}
          name={name}
          value={amountWithdraw}
          onChange={(e) => setAmountWithdraw(e.target.value)}
          buttonName="WITHDRAW"
        />
        <Button className={styles.button} onClick={() => setAmountWithdraw(balanceOf)}>
          MAX
        </Button>
      </div>
    </div>
  );
};

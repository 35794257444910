import React, {
  FC,
} from 'react';

import { Card } from 'components';

import Web3 from 'web3';
import styles from './styles.module.scss';
import { InfoNFT } from '../interface';
import { ROUTES } from '../../../../constants';

interface Props {
  images: InfoNFT['offer_punk'];
  collectionNumber: string;
  collectionName: string;
}

export const Extra: FC<Props> = ({ images, collectionNumber, collectionName }) => {
  const getLink = collectionNumber ?
    (idx) => ROUTES.carsItemLink(collectionName, idx, collectionNumber) :
    (idx) => ROUTES.punksItemLink(collectionName, idx);

  return (
    <div className={styles.list}>
      <div className={styles.container}>
        {
          images.map((img) => (
            <Card
              key={img.idx}
              idx={img.idx}
              collection={{
                name: collectionName || '',
                number: collectionNumber ? +collectionNumber : null,
              }}
              to={getLink(img.idx)}
              tags={img.attributes}
              rank={img.rank}
              type={img.type}
              value={Web3.utils.fromWei(img.min_value, 'ether')}
            />
          ))
        }
      </div>
    </div>
  );
};

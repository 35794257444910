import twitter from '../assets/images/media/twitter.png';
import discord from '../assets/images/media/discord.png';
import telegram from '../assets/images/media/telegram.png';
import youtube from '../assets/images/media/youtube.png';
import medium from '../assets/images/media/medium.png';
import git from '../assets/images/media/git.png';

type MediaImg = {
  name: string,
  img: string,
  href: string,
};

export const MEDIA_IMG: MediaImg[] = [
  {
    img: twitter,
    name: 'twitter',
    href: 'https://twitter.com/crosspunks',
  },
  {
    img: discord,
    name: 'discord',
    href: 'https://discord.gg/AyZPkypcfZ',
  },
  {
    img: telegram,
    name: 'telegram',
    href: 'https://t.me/crosspunks',
  },
  {
    img: youtube,
    name: 'youtube',
    href: 'https://www.youtube.com/channel/UCCycMj-nlN1brjI_IhmS_sA',
  },
  {
    img: medium,
    name: 'medium',
    href: 'https://medium.com/@crosspunks',
  },
  {
    img: git,
    name: 'git',
    href: 'https://github.com/crosspunks',
  },
];

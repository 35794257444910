import { Button, Text, Title } from 'components';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import personImg from '../../../assets/images/gamefi/gamefi_03_1.png';
import stakingImg from '../../../assets/images/gamefi/gamefi_03_2.png';
import styles from './styles.module.scss';

export const Staking: FC = () => (
  <div className={styles.container}>
    <img src={personImg} alt="image" className={styles.person} />
    <div className={styles.staking}>
      <Title className={styles.title} size="l">
        STAKING
      </Title>
      <img src={stakingImg} alt="staking" className={styles.stakingImage} />
      <Text className={styles.text}>
        Stake to fill up your tanks!
      </Text>
      <Link to={ROUTES.staking} className={styles.link}>
        <Button className={styles.stakingButton}>
          STAKING
        </Button>
      </Link>
    </div>
  </div>
);

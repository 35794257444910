import React, { FC } from 'react';
import cn from 'classnames';
import { MEDIA_IMG } from '../../constants';

import styles from './styles.module.scss';

type Props = {
  className?: string,
};

export const MediaImg: FC<Props> = ({ className }) => {
  const imgs = MEDIA_IMG.map(({ name, img, href }) => (
    <a href={href} target="_blank" key={name}>
      <img
        key={name}
        src={img}
        alt={name}
        className={styles.media}
      />
    </a>
  ));

  return (
    <div className={cn(styles.container, className)}>
      {imgs}
    </div>
  );
};

import React, { FC, ReactNode, PropsWithChildren } from 'react';
import Modal from 'react-modal';

import { ReactComponent as CloseModal } from 'assets/icons/cross.svg';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  onRequestClose: (isOpen: boolean) => void,
  children: ReactNode,
  noCloseIcon?: boolean,
  className?: string,
  onAfterOpen?: () => void,
};

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'relative',
    padding: 0,
    overflow: 'hidden',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const ModalWrap: FC<PropsWithChildren<Props>> = ({
  isOpen,
  noCloseIcon = true,
  onRequestClose,
  children,
  className = '',
  onAfterOpen = () => {
  },
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={customStyles}
    className={cn(styles.modal, className)}
    ariaHideApp={false}
    onAfterOpen={onAfterOpen}
  >
    {noCloseIcon ? (
      <div className={styles.closeModalWrapper}>
        <CloseModal className={styles.closeModal} onClick={() => onRequestClose(false)} />
      </div>
    ) : null}
    {children}
  </Modal>
);

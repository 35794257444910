import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

export const ContractInfo = ({
  className,
  icon,
  name,
  address,
}) => (
  <div className={cn(styles.contractInfo, className)}>
    <div className={styles.iconWrapper}>
      <img
        className={styles.icon}
        src={icon}
        alt={icon}
      />
    </div>
    <div className={styles.info}>
      <p className={styles.text}>
        {name}
        :
      </p>
      <a
        href={`https://etherscan.io/address/${address}`}
        className={cn(styles.text, styles.link)}
        target="_blank"
      >
        {address}
      </a>
    </div>
  </div>
);

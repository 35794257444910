import React, {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from 'react';
import cn from 'classnames';
import { Loader } from 'components';

import styles from './styles.module.scss';

type Props = {
  className?: string,
  onClick?: MouseEventHandler,
  loading?: boolean;
  disabled?: boolean;
  Icon?: ReactNode;
};

export const Button: FC<PropsWithChildren<Props>> = ({
  onClick,
  className,
  Icon,
  loading,
  disabled,
  children,
}) => (
  <button
    disabled={disabled}
    onClick={loading ? () => {} : onClick}
    type="button"
    className={cn(styles.button, className)}
  >
    {Icon && (
      <div className={styles.icon}>
        {Icon}
      </div>
    )}
    {loading ? <Loader /> : children}
  </button>
);
